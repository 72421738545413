<template>
    <div id="main" >
        
        
    </div>
</template>
<script>
export default {
    data(){
        return{
            map:null,
        }
    },
    mounted(){
        this.initMap()
    },
    methods:{
        initMap(){
            const _this = this;
            this.map = new BMapGL.Map('main');
            let point = new BMapGL.Point(118.10864844924734,24.51786926806679)
            this.map.centerAndZoom(point, 17);  // 初始化地图,
            this.map.enableScrollWheelZoom(true);
            let marker = new BMapGL.Marker(point);
            this.map.addOverlay(marker)
            var opts = {
                width : 200,     // 信息窗口宽度
                height: 100,     // 信息窗口高度
                title :  `<div class="title">
            厦门历思科技服务有限公司
        </div>`, // 信息窗口标题
                offset:{
                    height:-20,
                    width:0
                }
            }
            var infoWindow = new BMapGL.InfoWindow(` <div class="content">
                <p class="item">地址：中国福建省厦门市湖里区湖里大道33号7层西侧</p>
                <p class="item">电话：(0592)2283132</p>
            </div>`, opts);  // 创建信息窗口对象 
            marker.addEventListener("click", function(){          
                _this.map.openInfoWindow(infoWindow, point); //开启信息窗口
            }); 
        }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
#main{
    width:80vw;
    height:50vh;
    min-width:300px;
    border: #d5d5d5 solid 1px;
    margin: 0 auto;
}
/deep/.title{
    color: #284c9f;
    font-size: 16px;
}
/deep/.anchorBL{
    display:none;
}
</style>
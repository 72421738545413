<template>
  <div class="main">
    <Title>联系我们</Title>
    <Map class="map"></Map>
    <div class="flex">
      <div class="box1">
        <p class="title">集团信息</p>
        <ul class="ul">
          <li class="li">地址：中国福建省厦门市湖里区湖里大道33号7层西侧</li>
          <li class="li">邮箱：lisitech@163.com</li>
          <li class="li">主页：http://www.lisitech.com</li>
          <li class="li">
            电话：0592-2283132 &nbsp; &nbsp; &nbsp; &nbsp; 400-004-8820
          </li>
          <li class="li">传真：0592-2283932</li>
          <li class="li">邮编：361000</li>
        </ul>
      </div>
      <div class="box2">
        <p class="title">在线留言</p>
        <ul class="ul">
          <li class="li2"><input class="input" placeholder="公司名称" /></li>
          <li class="li2"><input class="input" placeholder="您的姓名" /></li>
          <li class="li2"><input class="input" placeholder="您的电话" /></li>
          <li class="li2"><input class="input" placeholder="请输入留言" /></li>
        </ul>
        <el-button class="btn" type="primary">发送</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "@/components/Map";
// import Title from "@/components/Title"
export default {
  components: {
    Map,
    // Title
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.main {
  margin-bottom: 2rem;
  width: 80vw;
  margin: 0 auto;
  max-width: 1600px;
}
.map {
  max-width: 1600px;
  min-width: 196px !important;
}
.title {
  font-size: 1.6rem;
  text-align: left;
  color: #284c91;
  margin: 3rem;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.box1 {
}
.box2 {
  background: whitesmoke;
  width: 40%;
  min-width: 300px;
  padding-left: 3rem;
}
.ul {
  list-style: none;
  text-align: left;
  line-height: 2.5rem;
  padding-left: 0;
}
.li2 {
  margin-top: 20px;
}
.input {
  line-height: 30px;
  width: 80%;
  border: none;
  background: whitesmoke;
  border-bottom: #ebebeb solid 1px;
  font-size: 1rem;
}
.li {
}
.btn {
  margin-top: 25px;
}
@media screen and (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
  .box2 {
    width: 80vw;
    padding-left: 0;
    min-width: 202px;
  }
  .btn {
    margin-bottom: 40px;
  }
}
</style>
